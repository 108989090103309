.app__menuitem {
    width: 100%;
    margin: 1rem 0;

    display: flex;
    flex-direction: column;
    transition: color 0.3s ease; 
}

.app__menuitem p {
    color: var(--color-white); 
    transition: color 0.3s ease, transform 0.3s ease;
    position: relative;
}

.app__menuitem:hover p {
    color: var(--color-tertiary); 
    transform: scale(1.05); 
    text-shadow: 0 0 1px var(--color-primary); 
}

.app__menuitem:hover p::after {
    transform: scaleX(1); 
}

.app__menuitem-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.app__menuitem-sub {
    width: 100%;
    margin-top: 0.2rem;
}

.app__menuitem-name {
    flex: 1;
}


.app__menuitem-price {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}