.app__video {
    background-color: var(--color-black);
    height: auto;
    position: relative;
}

.app__video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.app__video-overlay {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.2);
}

.app__video-overlay svg {
    color: var(--color-white);
}


.app__video-overlay_radius {
    width: 100%;
    height: 100%;
    cursor: pointer;
}